.feedback-message {
  transition: 0.1s ease-in-out;
}

.feedback-message:hover {
  border-bottom: 5px solid #009688;
}

.feedback-page-message {
  flex-direction: row;
  align-items: center;
  width: 900px;
}

.feedback-container {
  width: 650px;
}


@media screen and (max-width: 950px) {
  .feedback-page-message {
    width: unset;
  }
}


@media screen and (max-width: 600px) {
  .feedback-page-message {
    flex-direction: column;
  }

  .feedback-container {
    width: unset;
  }

  .feedback-text {
    text-align: center;
  }
}