.AboutUs--content {
  margin-left: auto;
  margin-right: auto;
  max-width: 950px;
}

.AboutUs--title {
  margin-top: 50px;
  margin-bottom: 20px;
}

.AboutUs--title h5 {
  text-align: center;
}

.AboutUs--row img {
  margin-top: 35px;
  margin-bottom: 35px;
  display: block;
  max-width: 100%;
}
