.PageTitle {
  margin-top: 57px;
  background-color: black;
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
}

.MainTitle {
  margin-top: unset;
  padding-top: 30px;
  padding-bottom: 30px;
}

.PageTitle h1 {
  margin: 0;
  font-weight: 300;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .PageTitle {
    margin-top: 50px;
  }

  .MainTitle {
    margin-top: unset;
    padding-bottom: 15px;
    padding-top: 15px;
  }
}
