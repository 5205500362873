@import "animate.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Container {
  margin-left: 80px;
  margin-right: 80px;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #009688 !important;
}

@media screen and (max-width: 900px) {
  .Container {
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media screen and (max-width: 750px) {
  .Container {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 26px !important;
  }

  h6 {
    font-size: 22px !important;
  }

  p {
    font-size: 16px !important;
  }
}

.MuiSvgIcon-root {
  padding: 0 !important;
}

.MuiDialog-root {
  border-radius: 12px;
}

.MuiButton-root {
  border-radius: 12px !important;
}
