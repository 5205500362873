.BuyWidget {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.BuyWidget--buttons {
  display: flex;
  align-items: center;
}

.BuyWidget--buttons > span {
  display: block;
  width: 35px;
  text-align: center;
}

.BuyWidget .MuiIconButton-root {
  padding: 7.5px;
  align-items: center;
}

.BuyWidget.BuyWidget--card {
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
}

.BuyWidget.BuyWidget--column {
  flex-direction: column;
}

.BuyWidget--column .BuyWidget--buttons {
  justify-content: space-between;
  margin-bottom: 15px;
}

.BuyWidget .MuiButton-label {
  font-size: 17px !important;
}

.expired {
  display: none !important;
}

@media screen and (max-width: 1100px) {
  .expired {
    display: flex !important;
  }
}