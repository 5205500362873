.Collection--banner {
  margin-top: -80px;
  background-color: black;
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
}

.Collection-spinner {
  padding: 120px;
}

.Collection-spinner-off {
  padding: unset;
}

.Collection-spinner svg {
  display: flex !important;
  justify-content: center !important;
  margin-top: 100px !important;
  margin-bottom: 100px !important;
  margin: 0 auto !important;
}

.Collection--banner h1 {
  margin: 0;
  font-weight: 300;
  text-align: center;
}

.Collection--category {
  margin-bottom: 50px;
}

.Collection--category .Collection--category--info {
  display: flex;
  flex-direction: column;
}

.Collection--category .Collection--category--info h2 {
  margin: 0;
  font-weight: 400;
  font-family: "Roboto Condensed";
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  background-color: teal;
  color: white;
  margin-top: 50px;
}

.Collection--category .Collection--category--info p {
  font-size: 22px;
  font-weight: 300;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
}

.Collection--category--actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
  align-items: flex-end;
}

.Collection--category--action {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Collection--category--filter--name {
  font-weight: 300;
  font-size: 18px !important;
}

.Collection--paginator {
  display: flex;
  justify-content: flex-end;
}

.Collection--paginator a {
  flex: 1;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
  color: #009688;
  background-color: rgba(0, 150, 135, 0.23137254901960785);
  border: 1px solid;
}

.Collection--paginator a:hover {
  background-color: #82dbcf59;
}

.Collection--paginator a div {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .Collection--category--actions {
    flex-direction: column;
    align-items: center;
  }

  .Collection--category--sort {
    margin-bottom: 10px;
  }

  .Collection--category .Collection--category--info h2 {
    font-size: 22px;
  }

  .Collection--category .Collection--category--info p {
    font-size: 20px;
  }

  .Collection--item--image {
    width: 150px !important;
  }
}

@media screen and (max-width: 800px) {
  .Collection--banner {
    margin-top: 50px;
  }
}
