.Product--lot {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 40px;
}

.no-width {
  max-width: unset !important;
}

.Product--lot--info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 370px;
  width: 270px;
}

.Product--lot--info h1 {
  font-weight: 400;
  font-size: 30px;
}

.Product--lot--info > div > span {
  font-size: 16px;
  font-weight: 400;
  color: #686d73;
  line-height: 1.4;
}

.Product--lot--actions {
  display: flex;
  flex-direction: column;
}

.Product--lot--actions .MuiButtonGroup-root {
  margin-top: 30px;
  margin-bottom: 20px;
}

.Product--additional--options {
  width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Product--lot--actions .Product--lot--buy {
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  font-size: 16px;
  color: white;
}

.Product--carousel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  position: relative;
  max-width: 50%;
}

.Product--carousel--container {
  display: flex;
  padding: 25px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Product--carousel--slide {
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;

  display: none;
  height: 350px;
  width: 350px;
}

.Product--carousel--slide.current {
  display: block;
}

.Product--carousel--controls {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 50%;
}

.Product--carousel--controls .MuiSvgIcon-root {
  padding: 0;
}

.Product--carousel--indicator {
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
  justify-content: center;
}

.Product--carousel--dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: rgba(114, 114, 114, 0.185);
  cursor: pointer;
  margin-left: 2px;
  margin-right: 2px;
}
.Product--carousel--dot:hover {
  background-color: rgba(114, 114, 114, 0.301);
}
.Product--carousel--dot.current {
  background-color: rgba(114, 114, 114, 0.301);
}

.Product--additional {
  display: grid;
  grid-template-columns: repeat(3, 0.75fr);
  gap: 40px;
}

.Product--additional .MuiTypography-h6 {
  font-family: "Roboto Condensed";
  font-size: 1.6rem;
  margin-bottom: 15px;
}

.Product--additional div {
  text-align: center;
}

.MuiDialogContent-root {
  width: 100% !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

.Product--feedback {
  padding: 50px 0;
}

.Product--feedback-btn {
  padding: 20px 0;
  display: flex;
  justify-content: center;
}

.feedback-message {
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 600px;
  border: 1px solid #009688;
  border-radius: 5px;
  margin: 15px auto;
  position: relative;
}

.feedback-top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.feedback-text {
  overflow-y: auto;
  width: 100%;
}

.feedback-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 90%;
}

.deleteForeverIcon {
  position: absolute !important;
  right: 5px;
  bottom: 5px;
}

.feedback-top > div > p {
  font-weight: bold;
  font-size: 19px;
}

.feedback-top > div > span {
  opacity: 0.5;
  font-size: 14px;
}

.feedback-bottom > div > p {
  font-size: 18px;
}

.feedback--load {
  display: flex !important;
  margin: 0 auto !important;
}

.feedback-bottom > span {
  font-weight: lighter;
  font-size: 14px;
}

@media screen and (max-width: 1100px) {
  .Product--additional {
    grid-template-columns: 1fr;
  }
  .Product--additional div {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 800px) {
  .Product--lot {
    margin-top: 100px;
    flex-direction: column;
    align-items: center;
  }
  .Product--carousel {
    max-width: 100%;
  }
  .Product--lot--info {
    width: 100%;
    align-items: center;
    text-align: center;
  }
  .Product--lot .BuyWidget--column .BuyWidget--buttons {
    justify-content: center;
  }
  .Product--lot .BuyWidget--column .BuyWidget--buttons > span {
    margin-left: 20px;
    margin-right: 20px;
  }

  .feedback-message {
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .feedback-top {
    flex-direction: column;
    text-align: center;
  }

  .feedback-top > span {
    margin: 0 auto !important;
  }
}
