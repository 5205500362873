.Collection--items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 60px 60px;
}

.Collection--items a {
  text-decoration: none;
  color: unset;
  margin-bottom: 40px;
}

.Collection--item--wrapper {
  text-decoration: none;
  color: unset;
}

.Collection--item {
  padding: 25px;
  border: 1px solid #80808040;
  border-radius: 6px;
  text-align: center;
  color: black;
  width: 100%;
  position: relative;
}

.Collection--item--sale {
  background-color: #e73636;
  color: white;
  border: 1px solid transparent;
  border-radius: 10px;
  width: 84px;
  height: 50px;
  position: absolute;
  top: -30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Collection--item--gift {
  right: 10px !important;
  top: -30px !important;
  position: absolute;
}

.Collection--item--gift_text {
  background-color: #212529de;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  right: 1px;
  top: -38px;
  /* transition: 0.3s ease-in-out; */
  visibility: hidden;
}

.Collection--item--gift:hover .Collection--item--gift_text {
  visibility: visible;
}

.new {
  width: 100px;
  background-color: #006400;
  left: 120px;
}

.Collection--item .MuiSvgIcon-root {
  padding: 0;
}

.Collection--item .MuiTypography-h6 {
  font-family: "Roboto Condensed";
  font-weight: 400;
  font-size: 24px;
}

.Collection--item .MuiTypography-subtitle1 {
  margin-bottom: 10px;
  color: rgb(121, 121, 121);
}

.Collection--item--image {
  height: 290px;
  width: 290px;
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  margin: auto;
}

.sales_price {
  text-decoration: line-through;
}

.new_price {
  margin-bottom: 0;
  font-weight: 600;
  color: rgb(218, 85, 85);
}

.no_margin_btm {
  margin-bottom: 1rem !important;
}

@media (max-width: 1750px) {
  .Collection--items {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1400px) {
  .Collection--items {
    .Collection--item--image {
      height: 170px;
    }
  }
}

@media (max-width: 1250px) {
  .Collection--items {
    grid-template-columns: repeat(2, 1fr);

    .Collection--item--image {
      height: 290px;
    }
  }
}

@media screen and (max-width: 930px) {
  .Collection--items {
    .Collection--item--image {
      height: 200px;
      width: 200px;
    }
  }
}

@media (max-width: 850px) {
  .Collection--item .MuiTypography-h6 {
    font-size: 20px;
  }
}

@media (max-width: 750px) {
  .Collection--items {
    grid-template-columns: repeat(1, 1fr);
  }
}
