.footer-wrapper {
  border-top: 1px solid rgba(211, 211, 211, 0.39);
  margin-top: 50px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 30px;
}

.footer-top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.fcol {
  display: flex;
  flex-direction: column;
}

.signUp {
  form {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
}

.footer__title {
  color: gray;
  font-size: 21px;
  cursor: pointer;
}

.footer__list {
  color: black;
  cursor: pointer;
  display: block;
  p {
    margin-right: 10px;
  }
  .active {
    display: block;
  }
}

.unactive {
  display: none;
}

.footer__list {
  a {
    color: unset;
    text-decoration: none;
  }
  p::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: #069682;
    margin-top: 3px;
    transition: width 0.3s;
  }
}

.MuiSvgIcon-root {
  padding: 0 12px;
}

.socials {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.instagram:hover {
  color: #069682;
  transition: 0.2s ease-in;
}

.facebook:hover {
  color: #069682;
  transition: 0.2s ease-in;
}

.pinterest:hover {
  color: #069682;
  transition: 0.2s ease-in;
}

.footer__list {
  display: flex;
  p:hover::after {
    width: 100%;
    transition: width 0.3s;
  }
}

@media screen and (max-width: 780px) {
  .footer__list {
    flex-direction: column;
    align-items: center;
  }
  .footer__list p {
    margin-right: 0;
  }
}

.MuiButton-containedSecondary {
  background-color: #069682;
}

.MuiButton-containedSecondary:hover {
  background-color: #069682;
}

.MuiInputBase-root {
  margin-bottom: 20px;
}

.MuiFormLabel-root {
  color: black;
}

.MuiInputBase-input {
  color: black;
  width: 250px;
}

.MuiInput-underline:before {
  border-bottom: 2px solid black;
}

.rights {
  display: flex;
  justify-content: center;
  color: black;
  text-align: center;
}

.rights {
  margin: 20px 10px;
  a {
    color: gray;
    text-decoration: none;
  }
}

.terms {
  display: flex;
  align-items: center;
}

.newTerms {
  display: none;
  justify-content: center;
  a {
    color: gray;
    text-decoration: none;
  }
}

@media screen and (max-width: 1000px) {
  .footer-top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer__title {
    text-align: center;
  }
  .fcol {
    margin-bottom: 30px;
  }
  .signUp .MuiButtonBase-root {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .footer_list a {
    font-size: 17px;
  }
}

@media screen and (max-width: 600px) {
  .footer__title:hover {
    color: #069682;
    transition: 0.2s ease-in;
  }

  .footer__title {
    font-size: 22px;
  }

  .signUp .MuiButton-label {
    font-size: 15px;
  }
}
