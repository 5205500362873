.Home--video {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 40px;
}

.Product .Container {
  margin-top: 95px;
}

.Home--video h5 {
  padding-bottom: 50px;
  font-family: "Roboto Condensed";
}

.HomeSlider {
  margin-top: 65px;
}

.HomeSlider--item {
  width: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 4px;
}

.HomeSlider--image {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.HomeSlider--item .Container {
  height: 100%;
}

.HomeSlider--item--info {
  display: flex;
  justify-content: flex-end;
  color: white;
  align-items: center;
  padding-top: 80px;
  height: 100%;
  background: none !important;
  opacity: 1 !important;
  transition: none !important;
}

.HomeSlider--item--background {
  padding: 25px;
  max-width: 650px;
  border-radius: 6px;
  background-color: #2b2b2b91;
}

.HomeSlider--item h4 {
  margin-bottom: 26px;
}

.HomeSlider--item a {
  text-decoration: none;
}

.carousel .thumbs-wrapper {
  display: none !important;
}

.Products--photoslider--dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
  background-color: rgba(128, 128, 128, 0.377);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Products--photoslider--dot.current {
  background-color: white;
}

iframe {
  width: 800px;
}

.youtube--videos {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.youtube--videos iframe {
  margin: 20px 0;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1380px) {
  .HomeSlider {
    margin-top: 53px;
  }
}

@media screen and (max-width: 800px) {
  .HomeSlider--item h4 {
    margin-bottom: 26px;
    font-size: 16px;
  }

  .HomeSlider--item .MuiButton-containedPrimary {
    font-size: 11px !important;
  }

  .HomeSlider--item--background {
    margin-top: 50px;
  }

  iframe {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .HomeSlider--item h4 {
    margin-bottom: 10px;
    font-size: 11px;
  }

  .MuiButton-containedPrimary {
    font-size: 9px !important;
  }

  .HomeSlider--item--background {
    margin-top: 70px;
    padding: 5px;
  }
}

/* Fix slider issues */
.carousel .carousel-status {
  display: none;
}
.carousel .slide img {
  width: inherit !important;
}
