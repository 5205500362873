.Contacts--content {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.Contacts--row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.Contacts--row h5 {
  margin-bottom: 15px;
}

.Contacts--row .Contacts--items {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.Contacts--row .bold {
  font-weight: 500;
}
