.Faq--content {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.Faq--title {
  margin-top: 50px;
  margin-bottom: 20px;
}

.Faq--title h5 {
  text-align: center;
}

.Faq--row ul {
  margin-bottom: 20px;
}

.Faq--row ul > li {
  list-style-type: circle;
}

.Faq--last {
  margin-top: 50px !important;
}
