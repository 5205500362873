.SearchDialog {
  margin-top: 100px;
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 850px) {
    .SearchDialog {
        max-width: 80%;
    }
}

@media screen and (max-width: 660px) {
    .SearchDialog {
        max-width: 90%;
    }
}

.SearchDialog > .MuiPaper-root .MuiIconButton-root {
  padding: 7.5px !important;
}

.SearchDialog--results {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
}

.SearchDialog--results::-webkit-scrollbar {
  width: 10px;
  background-color: rgb(113, 211, 178);
}

.SearchDialog--results::-webkit-scrollbar-thumb {
  background: rgb(69, 122, 105);
}

.SearchDialog--item {
  cursor: pointer;
  border-radius: 0 !important;
  background-color: #009688 !important;
  display: flex;
}

.SearchDialog--item:hover {
  background-color: #3e3e3e !important;
}

.SearchDialog--item:not(:last-child) {
  border-bottom: 2px solid white;
}

.SearchDialog--results .MuiTypography-root {
  font-family: 'Merriweather Sans';
  color: white;
}