header {
  background-color: white;
  box-shadow: 0px 1px 6px #cccccc85;
  margin-bottom: 80px;
  display: flex;
  justify-content: space-between;

  position: fixed;
  top: 0;
  width: 100%;
  align-items: center;
  padding-left: 10px;
  z-index: 9;
}

header .Header--language .MuiPaper-root {
  width: 80px;
}

.user-account {
  display: flex;
}

.account-pointer {
  color: #009688;
  font-size: 18px;
  width: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
}

header .Header--language .MuiPaper-root li {
  justify-content: center !important;
}

header .MuiIconButton-root {
  display: none;
  align-self: center;
  padding: 7.5px;
}

header .header-widgets {
  align-self: center;
  padding-right: 80px;
  display: flex;
}

.header-nav {
  display: flex;
  margin-left: -10px;
  height: 100%;
  padding-left: 80px;
  padding-right: 80px;
}

.header-nav-content {
  display: flex;
  align-items: baseline;
}

.header-nav-adaptive-cont {
  display: flex;
  padding-top: 40px;
  height: calc(100% - 50px) !important;
  width: 100%;
  overflow-y: auto;
}

.header-nav-adaptive-cont::-webkit-scrollbar {
  width: 10px;
  background-color: rgb(113, 211, 178);
}

.header-nav-adaptive-cont::-webkit-scrollbar-thumb {
  background: rgb(69, 122, 105);
}

.header-nav-actions {
  display: flex;
  align-items: center;
}

.header-category-title {
  margin: 10px;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  color: black;
  font-family: "Roboto Condensed";
  font-size: 18px;
  position: relative;
  flex-direction: column;
}

.header-category-name {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 15px;
  padding-top: 15px;
}

.header-category-name:hover,
.header-category-title.hovered .header-category-name {
  background-color: rgba(211, 211, 211, 0.555);
}

.header-category-title .header-category-list {
  height: 0;
}

.header-category-title.hovered .header-category-list {
  height: auto;
}

.header-category-title .MuiButtonBase-root {
  height: 0;
  color: white;
  padding-left: 35px;
  padding-right: 35px;
  font-family: "Roboto Condensed";
  font-size: 18px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-category-title.hovered .MuiButtonBase-root {
  height: 46px;
  color: black;
}

.header-logo {
  background-image: url("./logo_64_64.jpg");
  width: 64px;
  height: 64px;
  position: absolute;
  left: 5px;
}

.header-category-title a,
.header-category-title a:hover,
.header-category a:visited {
  color: black;
  text-decoration: none !important;
  color: black;
}

.header-category-title .header-category-list a,
.header-category-title .header-category-list a:hover,
.header-category-title .header-category-list a:visited {
  display: none;
}

.header-category-title.hovered .header-category-list a {
  display: unset;
}

// DIALOG STYLES

.ActionsDialog--order {
  padding-bottom: 20px;
  padding-top: 20px;
}

.ActionsDialog h6 {
  font-weight: 400 !important;
}

.ActionsDialog .MuiDialogTitle-root .MuiTypography-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ActionsDialog .MuiDialogTitle-root .MuiIconButton-root {
  padding: 7.5px;
}

.ActionsDialog--order ~ .ActionsDialog--order {
  border-top: 1px solid rgba(128, 128, 128, 0.26);
}

.ActionsDialog--group .ActionsDialog--row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ActionsDialog--bgroup {
  display: flex;
  justify-content: flex-end;
}

.ActionsDialog--group .ActionsDialog--row .MuiIconButton-root {
  padding: 7.5px;
}

.ActionsDialog--group .ActionsDialog--row h6 {
  color: rgba(75, 75, 75, 0.932);
}

.ActionsDialog--order--title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.ActionsDialog--order--item {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  align-items: center;
}

.ActionsDialog--order--image {
  height: 120px;
  width: 120px;
  background-position: center !important;
  background-size: contain !important;
  border: 1px solid #80808057;
  border-radius: 8px;
  margin-right: 20px;
  background-repeat: no-repeat !important;
}

.ActionsDialog--item--info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  max-width: 450px;
}

.ActionsDialog--item--name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (max-width: 750px) {
  .ActionsDialog--item--info {
    max-width: 350px;
  }
}

@media screen and (max-width: 550px) {
  .ActionsDialog--item--info {
    max-width: 250px;
  }

  .user-account {
    flex-direction: column;
  }

  .account-pointer {
    width: 108px;
    font-size: 14px;
  }
}

@media screen and (max-width: 550px) {
  .ActionsDialog--item--info {
    max-width: 200px;
  }
}

.ActionsDialog--total--price {
  padding-top: 30px;
  text-transform: uppercase;
  font-size: 18px !important;
  font-weight: bold;
}

.ActionsDialog--continue {
  display: flex;
  justify-content: flex-end;
  .MuiButtonBase-root {
    margin-left: auto;
  }
  a {
    text-decoration: none;
  }
}

.ActionsDialog--order--item .MuiIconButton-root {
  margin-left: auto;
}

.MuiDialog-paper.MuiPaper-rounded {
  border-radius: 12px;
}

.no--orders {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

// ADAPTIVE STYLES
//@media screen and (max-width: 1560px) {
header {
  height: 50px;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  z-index: 9;
}

.header-nav-adaptive-cont {
  overflow-x: hidden;
  display: unset;
}

header .MuiIconButton-root {
  display: flex;
}

.header-logo {
  position: relative;
}

.header-nav-content {
  position: fixed;
  flex-direction: column;
  height: 100%;
  background-color: white;
  width: 300px;
  left: 0;
  top: 50px;
  border-top: 1px solid rgba(128, 128, 128, 0.295);
  transform: translateX(-300px);
  transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.header-nav-content::after {
  display: block;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 300px;
  background-color: rgba(127, 221, 198, 0.295);
  width: 0;
  transition: width 0.3s ease;
}
.header-nav-content.opened {
  transform: translateX(0);
}
.header-nav-content.opened::after {
  width: 100vw;
}
.header-category-title {
  justify-content: space-between;
}
.header-category-title > .MuiPaper-root {
  border-radius: 0;
  left: 0;
  right: 0;
  border-top: 1px solid rgba(128, 128, 128, 0.295);
  border-bottom: 1px solid rgba(128, 128, 128, 0.295);
  box-shadow: none;
}
.header-category-title.hovered > .MuiPaper-root {
  top: 55px;
}
.header-category-title > .MuiPaper-root .MuiButtonBase-root {
  padding-left: 45px;
}
//}

@media screen and (max-width: 750px) {
  .MuiDialog-paperFullWidth {
    width: 100% !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin: 0 !important;
  }
  .MuiDialog-paperScrollPaper {
    height: calc(100% - 50px) !important;
  }
  .MuiDialog-scrollPaper {
    align-items: flex-end !important;
  }
  .ActionsDialog--order--image {
    height: 80px;
    width: 80px;
  }
}

@media screen and (max-width: 450px) {
  .header-nav-content {
    width: 100vw;
    transform: translateX(-100vw);
  }

  .header-nav {
    padding-right: 0;
    padding-left: 35px;
  }

  .header-nav-content::after {
    display: none;
  }
}

.MuiCircularProgress-root {
  height: 35px !important;
  width: 35px !important;
}

.AuthDialog--err {
  color: red;
}
