.Basket .Container {
  margin-top: 100px;
}

.titles {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 1fr 0.5fr;
  gap: 20px 20px;
  margin-bottom: 30px;
  font-size: 20px;
  font-family: "Roboto Condensed", sans-serif;
}

.item {
  border-top: 1px solid rgba(128, 128, 128, 0.404);
  gap: 20px 20px;
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 1fr 0.5fr;
  font-family: "Roboto", sans-serif;
}

.items {
  margin-bottom: 50px;
}

.Basket--empty h5 {
  text-align: center;
  padding-bottom: 50px;

  @media screen and (max-width: 960px) {
    padding-top: 50px;
  }
}

.remove__item {
  cursor: pointer;
  font-size: 32px;
}

.photo__item {
  width: 90px;
  height: 90px;
}

.add__item {
  cursor: pointer;
}

.MuiButton-containedSecondary {
  background-color: #069682;
}

svg.MuiSvgIcon-root:hover {
  color: #069682;
  cursor: pointer;
}

.MuiButton-containedSecondary:hover {
  background-color: #069682;
}

.quantity__item {
  padding: 0 10px;
}

.input__quantity {
  width: 40px;
  border: 1px solid black;
  outline: none;
  border-radius: 15px;
  text-align: center;
}

.basket__item {
  display: flex;
  align-items: center;
}

.item__info {
  display: flex;
  font-size: 18px;
  justify-content: flex-start !important;
}

.basket__item .MuiFormControl-marginDense {
  margin: 0;
}

.basket__item .MuiOutlinedInput-root {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 12px;
}

.basket__item .MuiIconButton-root {
  padding: 7.5px;
}

.quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.price__item {
  font-size: 19px;
}

.total__item {
  font-size: 19px;
}

.purchase {
  transition: 0.2s ease;
  outline: none;
  cursor: pointer;
  padding: 20px;
  margin-top: 20px;
  font-size: 22px;
  background-color: transparent;
  border: transparent;
  border-radius: 15px;
}

.purchase__button {
  display: flex;
  justify-content: flex-end;
}

.MuiButton-contained {
  margin-top: 40px;
  border-radius: 5px;
  font-size: 15px;
}

.MuiButton-text {
  padding: 0;
  border-radius: 15px;
}

.delete__item {
  cursor: pointer;
}

.modal-window {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-text {
  background-color: white;
  width: 425px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 23px;
  position: relative;
}

.close-modal {
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 850px) {
  .item {
    grid-template-columns: 0.85fr 1.5fr 0.15fr;
    padding-bottom: 15px;
    padding-top: 5px;
  }

  .photo__item {
    display: flex;
    justify-content: center;
    width: 70px;
    img {
      height: 65px !important;
      width: 65px !important;
    }
  }

  .titles {
    display: none;
  }

  .basket__item {
    justify-content: center;
  }

  .quantity {
    grid-row-start: 1;
    grid-column-start: 2;
  }

  .delete {
    grid-row-start: 1;
    grid-column-start: 3;
  }
}

@media screen and (max-width: 750px) {
  .item {
    p {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 670px) {
  .shoppingCart {
    width: 100%;
  }

  .remove__item {
    left: 1px;
  }
}

@media screen and (max-width: 600px) {
  .product {
    margin-left: 0;
  }
}

@media screen and (max-width: 520px) {
  .item {
    grid-template-columns: 1fr 1fr;
  }
  .basket__item .MuiIconButton-root {
    margin-left: auto;
  }

  .quantity {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: -20px;
  }

  .delete {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
  }

  .item__info {
    font-size: 16px;
  }
  .price__item {
    font-size: 16px;
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
  }
  .total__item {
    font-size: 16px;
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 2;
  }

  .titles {
    font-size: 18px;
  }
  .MuiSvgIcon-root {
    font-size: 26px;
  }
  .item {
    flex-wrap: wrap;
    // height: 140px;
  }
}

@media screen and (max-width: 450px) {
  .modal-text {
    width: 250px;
    height: 200px;
    display: flex;
    justify-content: center;
  }

  #transition-modal-title {
    text-align: center;
  }
}

.MuiFormHelperText-root.MuiFormHelperText-root {
  margin-top: -16px !important;
}

/* Hide arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
