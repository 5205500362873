.Support--row {
  margin-top: 50px;
  text-align: center;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.Support--title {
  margin-bottom: 10px;
}

.Support--title h5 {
  text-align: center;
}
